import React, { Component } from "react";
import productsdata from "../data/productsdata";
import ProductCard from "../component/product/ProductCard";
import Modal from "react-modal";
import ProductDetails from "../component/product/ProductDetails";
import ContactForm from "../elements/contact/ContactForm";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faTimes } from "@fortawesome/free-solid-svg-icons";
import { motion } from "framer-motion";
import "../assets/css/products.css";
import { LeafTitle } from "./../component/common/LeafTitle";
import Header from "../component/header/Header";


Modal.setAppElement("#root");

class Products extends Component {
  state = {
    isProductModalOpen: false,
    isQuoteModalOpen: false,
    selectedProduct: null,
    quoteProductName: "",
  };

  openProductModal = (product) => {
    this.setState({ isProductModalOpen: true, selectedProduct: product });
  };

  closeProductModal = () => {
    this.setState({ isProductModalOpen: false, selectedProduct: null });
  };

  openQuoteModal = (productName) => {
    this.setState({ isQuoteModalOpen: true, quoteProductName: productName });
  };



  closeQuoteModal = () => {
    this.setState({ isQuoteModalOpen: false });
  };

  render() {
    return (
      <React.Fragment>
         <Header
                headerPosition="header--fixed sticky logoresize"
                logo="all-dark"
                color="color-black"
              />
        <motion.div
          initial={{ y: 200 }}
          whileInView={{ y: 0 }}
          transition={{ duration: 1 }}
          className="products-wrapper"
        >
          <LeafTitle title={"Our Products"} />
          <div className="products-grid">
            {productsdata.map((value, index) => (
              <ProductCard
                key={index}
                product={value}
                onClick={() => this.openProductModal(value)}
              />
            ))}
          </div>
        </motion.div>

        <Modal
          isOpen={this.state.isProductModalOpen}
          onRequestClose={this.closeProductModal}
          contentLabel="Product Details Modal"
          style={{
            overlay: {
              backgroundColor: "rgba(0, 0, 0, 0.75)",
            },
            content: {
              zIndex: "9999",
              top: "55%",
              left: "50%",
              right: "auto",
              bottom: "auto",
              marginRight: "-50%",
              transform: "translate(-50%, -50%)",
              padding: "10px",
              borderRadius: "10px",
              width: "100%",
              maxWidth: "900px",
              background: "#fff",
            },
          }}
        >
          {this.state.selectedProduct && (
            <ProductDetails
              product={this.state.selectedProduct}
              openQuoteModal={this.openQuoteModal}
            />
          )}
          <button className="modal-close-btn" onClick={this.closeProductModal}>
            <FontAwesomeIcon icon={faTimes} />
          </button>
        </Modal>

        <Modal
          isOpen={this.state.isQuoteModalOpen}
          onRequestClose={this.closeQuoteModal}
          contentLabel="Quote Modal"
          style={{
            overlay: {
              backgroundColor: "rgba(0, 0, 0, 0.75)",
            },
            content: {
              zIndex: "9999",
              top: "60%",
              left: "50%",
              right: "auto",
              bottom: "auto",
              marginRight: "-50%",
              transform: "translate(-50%, -50%)",
              padding: "10px",
              borderRadius: "10px",
              width: "100%",
              maxWidth: "1000px",
              background: "#fff",
            },
          }}
        >
          <h2 style={{ textAlign: "center" }}>Get a Quote</h2>
          <ContactForm  productName={this.state.quoteProductName}  />
          <button className="modal-close-btn" onClick={this.closeQuoteModal}>
            <FontAwesomeIcon icon={faTimes} />
          </button>
        </Modal>
      </React.Fragment>
    );
  }
}

export default Products;
