// React Required
import React, { Component } from "react";
import ReactDOM from "react-dom/client";
import "./index.scss";
import "./assets/css/productStyle.css";
import { BrowserRouter, Switch, Route } from "react-router-dom";
import * as serviceWorker from "./serviceWorker";
import PageScrollTop from "./component/PageScrollTop";
import FooterTwo from "./component/footer/FooterTwo";
import FarmvalyHome from "./home/FarmvalyHome";
import ProductDetails from "./component/product/ProductDetails";
import productsdata from "./data/productsdata";
import Login from "./component/Login";
import AboutUs from "./home/AboutUs";
import OurBusiness from "./home/OurBusiness";
import ShopPage from "./home/ShopPage";
import ContactUs from "./home/ContactUs";
import Products from "./home/Products";
import Bangladesh from "./home/Bangladesh";
import error404 from "./elements/error404";

import './i18n';  
import Article from "./home/Article";
import ArticleDetails from "./elements/ArticleDetails";

class Root extends Component {
  state = {
    isAuthenticated: false,
  };

  handleLogin = (status) => {
    this.setState({ isAuthenticated: status });
  };

  render() {
    const { isAuthenticated } = this.state;

    return (
      <BrowserRouter basename={"/"}>
        <PageScrollTop>
          {isAuthenticated ? (
            <>
              <Switch>
                <Route exact path={`/`} component={FarmvalyHome} />
                <Route exact path={`/about-us`} component={AboutUs} />
                <Route exact path={`/our-business`} component={OurBusiness} />
                <Route exact path={`/shop`} component={ShopPage} />
                <Route exact path={`/contact`} component={ContactUs} />
                <Route exact path={`/products`} component={Products} />
                <Route exact path={`/bangladesh`} component={Bangladesh} />
                <Route exact path={`/article`} component={Article} />
                <Route exact path={`/article-details/:id`} component={ArticleDetails} />
                <Route
                  exact
                  path={`/product/:id`}
                  render={(props) => (
                    <ProductDetails {...props} products={productsdata} />
                  )}
                />
                <Route component={error404} />
              </Switch>
              <FooterTwo />
            </>
          ) : (
            <Login onLogin={this.handleLogin} />
          )}
        </PageScrollTop>
      </BrowserRouter>
    );
  }
}

const root = ReactDOM.createRoot(document.getElementById("root"));
root.render(<Root />);
serviceWorker.register();
