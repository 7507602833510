import React, { useState } from "react";
import axios from "axios";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";

function ContactForm({ productName }) {
  const [formData, setFormData] = useState({
    fullname: "",
    email: "",
    countryCode: "+1",
    phoneNumber: "",
    subject: "",
    message: "",
  });
  const [loading, setLoading] = useState(false);

  // Handle form input changes
  const handleInputChange = (e) => {
    const { name, value } = e.target;
    setFormData((prevData) => ({ ...prevData, [name]: value }));
  };

  const sendEmail = async (e) => {
    e.preventDefault();
    setLoading(true);

    const API_KEY = process.env.REACT_APP_BREVO_API;

    const data = {
      sender: { email: "kayes.syl@gmail.com", name: formData.fullname },
      to: [{ email: "cse_2132020002@lus.ac.bd", name: "Kayes" }],
      subject: formData.subject,
      htmlContent: `
        <html>
        <head>
          <style>
            body {
              font-family: Arial, sans-serif;
              margin: 0;
              padding: 0;
              background-color: #f4f4f4;
            }
            .container {
              width: 100%;
              max-width: 600px;
              margin: 0 auto;
              background-color: #ffffff;
              padding: 20px;
              border: 1px solid #ddd;
              border-radius: 4px;
            }
            .header {
              text-align: center;
            }
            .header img {
              max-width: 180px;
            }
            .content {
              font-size: 16px;
              line-height: 1.5;
              color: #333;
            }
            .content p {
              margin: 10px 0;
              font-weight: 500;
            }
            .msgBOX {
              background-color: #015a27;
              color: white;
              text-align: justify;
              padding: 15px;
              border-radius: 8px;
            }
            .content strong {
              color: #015a27;
              font-weight: 700;
            }
            .content h2 {
              color: white;
              text-align: center;
              text-transform: uppercase;
              background-color: #015a27;
              padding: 10px;
              border-radius: 4px;
            }
            .footer {
              text-align: center;
              font-size: 14px;
              color: #777;
              padding-top: 20px;
              border-top: 1px solid #ddd;
            }
            @media only screen and (max-width: 600px) {
              .container {
                padding: 15px;
              }
              .header img {
                max-width: 150px;
              }
              .content {
                font-size: 14px;
              }
              .content h2 {
                font-size: 18px;
                padding: 8px;
              }
              .msgBOX {
                font-size: 14px;
              }
              .footer {
                font-size: 12px;
              }
            }
          </style>
        </head>
        <body>
          <div class="container">
            <div class="header">
              <img
                src="http://farmvaly.com/static/media/logo.33636fc7216d7e2c0ca5.png"
                alt="Farmvaly Logo"
              />
            </div>
            <div class="content">
              <h2>New Submission From Farmvaly</h2>
              <div class="mainContent">
                <p><strong>Name: </strong>${formData.fullname}</p>
                <p><strong>Email: </strong>${formData.email}</p>
                <p><strong>Phone: </strong>${formData.countryCode} ${
        formData.phoneNumber
      }</p>
                <p><strong>Product: </strong>${productName}</p>
                <p><strong>Subject:</strong> ${formData.subject}</p>
                <p><strong>Message:</strong></p>
                <div class="msgBOX">
                  <p>${formData.message}</p>
                </div>
              </div>
            </div>
            <div class="footer">
              <p>&copy; ${new Date().getFullYear()} Farmvaly. All rights reserved.</p>
            </div>
          </div>
        </body>
        </html>
      `,
    };

    try {
      const response = await axios.post(
        "https://api.brevo.com/v3/smtp/email",
        data,
        { headers: { "Content-Type": "application/json", "api-key": API_KEY } }
      );
      console.log("Email sent successfully", response.data);
      toast.success(
        "Your message has been successfully sent. We will contact you soon."
      );
    } catch (error) {
      console.error(
        "Error sending email:",
        error.response ? error.response.data : error.message
      );
      toast.error("There was an error sending your message. Please try again.");
    } finally {
      setLoading(false);
      e.target.reset();
      setFormData({
        fullname: "",
        email: "",
        countryCode: "+1",
        phoneNumber: "",
        subject: "",
        message: "",
      });
    }
  };

  return (
    <div>
      <form onSubmit={sendEmail} style={{ padding: "20px" }}>
        <div className="rn-form-group" style={{ marginBottom: "15px" }}>
          <input
            type="text"
            name="fullname"
            placeholder="Name *"
            required
            value={formData.fullname}
            onChange={handleInputChange}
          />
        </div>

        <div className="rn-form-group" style={{ marginBottom: "15px" }}>
          <input
            type="email"
            name="email"
            placeholder="Email *"
            required
            value={formData.email}
            onChange={handleInputChange}
          />
        </div>

        <div
          className="rn-form-group"
          style={{
            marginBottom: "15px",
            display: "flex",
            alignItems: "center",
            justifyContent: "center",
          }}
        >
          <select
            name="countryCode"
            required
            value={formData.countryCode}
            onChange={handleInputChange}
            style={{
              marginRight: "10px",
              padding: "10px",
              border: "1px solid #ccc",
              width: "150px",
              borderRadius: "5px",
              height: "50px",
            }}
          >
            <option value="+1">+1 (US)</option>
            <option value="+44">+44 (UK)</option>
            <option value="+91">+91 (IN)</option>
            <option value="+880">+880 (BD)</option>
            <option value="+33">+33 (FR)</option>
            <option value="+49">+49 (DE)</option>
            <option value="+61">+61 (AU)</option>
            <option value="+81">+81 (JP)</option>
            <option value="+55">+55 (BR)</option>
            <option value="+86">+86 (CN)</option>
            <option value="+27">+27 (ZA)</option>
            <option value="+90">+90 (TR)</option>
            <option value="+82">+82 (KR)</option>
            <option value="+34">+34 (ES)</option>
            <option value="+39">+39 (IT)</option>
            <option value="+7">+7 (RU)</option>
            <option value="+60">+60 (MY)</option>
            <option value="+971">+971 (AE)</option>
            <option value="+966">+966 (SA)</option>
            <option value="+91">+91 (IN)</option>
            <option value="+63">+63 (PH)</option>
            <option value="+45">+45 (DK)</option>
            <option value="+48">+48 (PL)</option>
            <option value="+20">+20 (EG)</option>
            <option value="+53">+53 (CU)</option>
            <option value="+98">+98 (IR)</option>
            <option value="+46">+46 (SE)</option>
            <option value="+31">+31 (NL)</option>
            <option value="+41">+41 (CH)</option>
            <option value="+352">+352 (LU)</option>
            <option value="+372">+372 (EE)</option>
            <option value="+370">+370 (LT)</option>
            <option value="+371">+371 (LV)</option>
            <option value="+90">+90 (TR)</option>
            <option value="+41">+41 (CH)</option>
          </select>

          <input
            type="text"
            name="phoneNumber"
            placeholder="Phone Number *"
            required
            value={formData.phoneNumber}
            onChange={handleInputChange}
            style={{ padding: "10px", border: "1px solid #ccc", flex: 1 }}
          />
        </div>

        <div className="rn-form-group" style={{ marginBottom: "15px" }}>
          <select
            name="subject"
            required
            value={formData.subject}
            onChange={handleInputChange}
            style={{
              marginRight: "10px",
              padding: "10px",
              border: "1px solid #ccc",
            }}
          >
            <option value="">Select Subject</option>
            <option value="General">General</option>
            <option value="Export">Export</option>
            <option value="Import">Import</option>
            <option value="Local Trading">Local Trading</option>
            <option value="Online Business">Online Business</option>
          </select>
        </div>

        <div className="rn-form-group" style={{ marginBottom: "15px" }}>
          <textarea
            style={{ padding: "0 15px", minHeight: "110px" }}
            name="message"
            placeholder="Message"
            required
            value={formData.message}
            onChange={handleInputChange}
          ></textarea>
        </div>

        <div
          className="rn-form-group"
          style={{ marginBottom: "15px", textAlign: "center" }}
        >
          <button
            className="rn-button-style--2 btn-solid"
            type="submit"
            disabled={loading}
          >
            {loading ? "Sending..." : "Submit"}
          </button>
        </div>
      </form>

      <ToastContainer />
    </div>
  );
}

export default ContactForm;
