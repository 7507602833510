import React, { useState } from "react";
import { motion } from "framer-motion";
import Modal from "react-modal";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faTimes } from "@fortawesome/free-solid-svg-icons";
import TextGenerate from "../../../TextGenerate/index";
import { useTranslation } from 'react-i18next';

import p1 from "../../../assets/images/products/p1.png";
import p2 from "../../../assets/images/products/p2.png";
import p3 from "../../../assets/images/products/p3.png";
import p4 from "../../../assets/images/products/p4.png";

Modal.setAppElement("#root");

const WhatWeDo = () => {
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [selectedItem, setSelectedItem] = useState(null);
  const [hoveredItem, setHoveredItem] = useState(null);
  const { t } = useTranslation();

  const items = [
    {
      bg: "#005825",
      direction: "rightLine",
      image: p1,
      title: "Export",
      description:
        "Corn Cob Meal is a versatile product used as a high-fiber feed supplement for livestock. It is made from the dried cobs of corn and is often used to enhance the diet of animals. Corn Cob Meal is known for its excellent fiber content and is commonly used in animal nutrition to promote healthy digestion and prevent digestive disorders.",
    },
    {
      bg: "#86B22B",
      direction: "leftLine",
      image: p2,
      title: "Local Trading",
      description:
        "Jackfruit is a tropical fruit known for its large size and distinctive sweet flavor. It is rich in vitamins, minerals, and antioxidants, making it a nutritious addition to any diet. The fruit can be enjoyed fresh or cooked, and its unique texture makes it a popular meat substitute in vegetarian and vegan dishes.",
    },
    {
      bg: "#9AC726",
      direction: "rightLine",
      image: p3,
      title: "Online Trading",
      description:
        "Pineapple is a tropical fruit known for its tangy-sweet flavor and high vitamin C content. It is a rich source of antioxidants and digestive enzymes, making it a healthy and refreshing choice for smoothies, salads, and desserts. Pineapple can be enjoyed fresh, juiced, or cooked in a variety of dishes.",
    },
    {
      bg: "#FFFFFF",
      direction: "leftLine",
      image: p4,
      title: "Import & Distribution",
      description:
        "Bananas are a popular fruit known for their natural sweetness and high potassium content. They are a great source of energy and essential nutrients, including vitamins B6 and C. Bananas can be eaten fresh, added to smoothies, or used in baking for a natural sweetness and moist texture.",
    },
  ];

  const openModal = (item) => {
    setIsModalOpen(true);
    setSelectedItem(item);
  };

  const closeModal = () => {
    setIsModalOpen(false);
    setSelectedItem(null);
  };

  return (
    <React.Fragment>
      <div className="about-wrapper whatwedoWrapper text-center">
        <div className="container">
          <motion.h2
            initial={{ y: 90, opacity: 0 }}
            whileInView={{ y: 0, opacity: 1 }}
            transition={{ delay: 0, duration: 0.5 }}
            className="title HEADtitle"
          >
            {t('whatWeDo.title')}
          </motion.h2>
          {items.map((item, index) => (
            <div
              className={`row whatwedorow row--35 ${index % 2 === 1 ? "flex-row-reverse" : ""}`}
              key={index}
            >
              <motion.div
                initial={{ y: 90, opacity: 0 }}
                whileInView={{ y: 0, opacity: 1 }}
                transition={{ delay: 0, duration: 0.5 }}
                className={`${item.direction} col-lg-5 col-md-12`}
              >
                <div className="thumbnail">
                  <img className="w-100" src={item.image} alt="About Images" />
                </div>
              </motion.div>

              <motion.div
                initial={{ y: 90, opacity: 0 }}
                whileInView={{ y: 0, opacity: 1 }}
                transition={{ delay: 0, duration: 0.5 }}
                className={`col-lg-7 col-md-12 whatwedotextbox asdf-${index}`}
                onClick={() => openModal(item)}
                onMouseEnter={() => setHoveredItem(index)}
                onMouseLeave={() => setHoveredItem(null)}
                style={{
                  backgroundColor: hoveredItem === index ? item.bg : "initial",
                  transition: "background-color 0.3s ease",
                }}
              >
                <div className="about-inner inner">
                  <div className={`${item.direction}`}>
                    <h3 className="title">{item.title}</h3>
                    <p className="description">
                      <TextGenerate text={item.description} />
                    </p>
                  </div>
                </div>
              </motion.div>
            </div>
          ))}
        </div>
      </div>

      {/* Modal for displaying content */}
      <Modal
        isOpen={isModalOpen}
        onRequestClose={closeModal}
        contentLabel="What We Do Details Modal"
        style={{
          overlay: {
            backgroundColor: "rgba(0, 0, 0, 0.75)",
          },
          content: {
            zIndex: "9999",
            top: "50%",
            left: "50%",
            right: "auto",
            bottom: "auto",
            marginRight: "-50%",
            transform: "translate(-50%, -43%)",
            padding: "20px",
            borderRadius: "10px",
            width: "90%",
            maxWidth: "600px",
            height: "auto",
            maxHeight: "80vh",
            background: "#fff",
            overflowY: "auto",
          },
        }}
      >
        {selectedItem && (
          <div className="whatwedotextboxModalwrapper">
            <div className="whatwedotextboxModalImageDiv">
              <img
                className=""
                src={selectedItem.image}
                alt={selectedItem.title}
                style={{ maxWidth: "400px" }}
              />
            </div>

            <h3 className="title whatwedotextboxModalh3">
              {selectedItem.title}
            </h3>
            <p className="whatwedotextboxModalp">
              {selectedItem.description}
            </p>
          </div>
        )}

        <button className="modal-close-btn" onClick={closeModal}>
          <FontAwesomeIcon icon={faTimes} />
        </button>
      </Modal>
    </React.Fragment>
  );
};

export default WhatWeDo;
