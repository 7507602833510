import p1 from "../assets/images/products/p1.png";
import p2 from "../assets/images/products/p2.png";
import p3 from "../assets/images/products/p3.png";
import p4 from "../assets/images/products/p4.png";
import p5 from "../assets/images/products/p5.png";
import p6 from "../assets/images/products/p6.png";
import p7 from "../assets/images/products/p7.png";
import p8 from "../assets/images/products/p8.png";
import p9 from "../assets/images/products/p9.png";
import p10 from "../assets/images/products/p10.png";
import p11 from "../assets/images/products/p11.png";
import p12 from "../assets/images/products/p12.png";
import p13 from "../assets/images/products/p13.png";
import p14 from "../assets/images/products/p14.png";
import p15 from "../assets/images/products/p15.png";
import p16 from "../assets/images/products/p16.png";
import p17 from "../assets/images/products/p17.png";

const productsdata = [
  {
    id: 1,
    name: "Corn Cob Meal (Commodity item)",
    imageUrl: [p1],
    details:
      "Corn Cob Meal is a versatile product used as a high-fiber feed supplement for livestock. It is made from the dried cobs of corn and is often used to enhance the diet of animals. Corn Cob Meal is known for its excellent fiber content and is commonly used in animal nutrition to promote healthy digestion and prevent digestive disorders.",
  },
  {
    id: 2,
    name: "Jackfruit (Fruit)",
    imageUrl: [p2],
    details:
      "Jackfruit is a tropical fruit known for its large size and distinctive sweet flavor. It is rich in vitamins, minerals, and antioxidants, making it a nutritious addition to any diet. The fruit can be enjoyed fresh or cooked, and its unique texture makes it a popular meat substitute in vegetarian and vegan dishes.",
  },
  {
    id: 3,
    name: "Pineapple (Fruit)",
    imageUrl: [p3],
    details:
      "Pineapple is a tropical fruit known for its tangy-sweet flavor and high vitamin C content. It is a rich source of antioxidants and digestive enzymes, making it a healthy and refreshing choice for smoothies, salads, and desserts. Pineapple can be enjoyed fresh, juiced, or cooked in a variety of dishes.",
  },
  {
    id: 4,
    name: "Banana (Fruit)",
    imageUrl: [p4],
    details:
      "Bananas are a popular fruit known for their natural sweetness and high potassium content. They are a great source of energy and essential nutrients, including vitamins B6 and C. Bananas can be eaten fresh, added to smoothies, or used in baking for a natural sweetness and moist texture.",
  },
  {
    id: 5,
    name: "Mushroom (Vegetable)",
    imageUrl: [p5],
    details:
      "Mushrooms are a versatile vegetable known for their rich, umami flavor and nutritional benefits. They are low in calories and high in vitamins, minerals, and antioxidants. Mushrooms can be used in a variety of dishes, from soups and stews to stir-fries and salads, adding depth and flavor to your meals.",
  },
  {
    id: 6,
    name: "Fresh Potato (Vegetable)",
    imageUrl: [p6],
    details:
      "Fresh potatoes are a staple vegetable known for their versatility and nutritional value. They are a good source of vitamins, minerals, and fiber. Potatoes can be prepared in numerous ways, including baking, boiling, mashing, and frying, making them a versatile ingredient in many dishes.",
  },
  {
    id: 7,
    name: "Dry Red Chili (Spice)",
    imageUrl: [p7],
    details:
      "Dry red chilies are a popular spice known for their intense heat and rich flavor. They are used to add a spicy kick to a variety of dishes, including curries, stews, and sauces. Dry red chilies can be ground into a powder or used whole to infuse dishes with a bold, fiery flavor.",
  },
  {
    id: 8,
    name: "Peanut kernel",
    imageUrl: [p8],
    details:
      "Peanut kernels are a nutritious snack and ingredient known for their rich, nutty flavor. They are high in protein, healthy fats, and essential nutrients. Peanuts can be eaten on their own, used in cooking, or made into products like peanut butter and peanut oil.",
  },
  {
    id: 9,
    name: "Raw Cashew Nut",
    imageUrl: [p9],
    details:
      "Raw cashew nuts are a rich source of healthy fats, protein, and essential vitamins and minerals. They have a mild, buttery flavor and can be enjoyed as a snack, used in cooking, or made into cashew butter and cashew milk. Cashews are a versatile ingredient in both savory and sweet dishes.",
  },
  {
    id: 10,
    name: "Cumin Seed",
    imageUrl: [p10],
    details:
      "Cumin seeds are a spice known for their distinctive, warm flavor and aroma. They are commonly used in various cuisines to add depth and complexity to dishes. Cumin seeds can be used whole or ground and are often added to spice blends, curries, and stews.",
  },
  {
    id: 11,
    name: "Rapeseed",
    imageUrl: [p11],
    details:
      "Rapeseed is a versatile crop used to produce rapeseed oil, which is known for its light flavor and high smoke point. It is a good source of healthy fats and can be used in cooking, baking, and salad dressings. Rapeseed oil is valued for its nutritional benefits and culinary versatility.",
  },
  {
    id: 12,
    name: "Black Sesame Seed (Single skin) (Oil Seed)",
    imageUrl: [p12],
    details:
      "Black sesame seeds are known for their rich, nutty flavor and high nutritional content. They are often used in cooking and baking for their unique taste and health benefits. Black sesame seeds are rich in antioxidants, calcium, and iron, making them a nutritious addition to various dishes.",
  },
  {
    id: 13,
    name: "Small White (Oil Seed)",
    imageUrl: [p13],
    details:
      "Small white oil seeds are known for their mild flavor and high oil content. They are often used in cooking and oil extraction. These seeds are a good source of essential fatty acids and can be used to add a subtle flavor and nutrition to a variety of dishes.",
  },
  {
    id: 14,
    name: "Yellow Sesame Seed (double skin) (Oil Seed)",
    imageUrl: [p14],
    details:
      "Yellow sesame seeds have a slightly sweet, nutty flavor and are commonly used in cooking and baking. They are rich in healthy fats, protein, and essential nutrients. Yellow sesame seeds are often used in recipes for their flavor and nutritional benefits, including in salads, bread, and dressings.",
  },
  {
    id: 15,
    name: "Black Sesame Seed (double skin) (Oil Seed)",
    imageUrl: [p15],
    details:
      "Black sesame seeds are valued for their intense flavor and high nutritional content. They are used in a variety of culinary applications, including baking and cooking. Black sesame seeds are rich in antioxidants, calcium, and iron, making them a healthy addition to many dishes.",
  },
  {
    id: 16,
    name: "Brown Sesame Seed (double skin) (Oil Seed)",
    imageUrl: [p16],
    details:
      "Brown sesame seeds are known for their rich flavor and nutritional benefits. They are used in cooking, baking, and oil extraction. Brown sesame seeds are a good source of protein, healthy fats, and essential vitamins and minerals, making them a versatile ingredient in many recipes.",
  },
  {
    id: 17,
    name: "Sesame Seed (Oil Seed)",
    imageUrl: p17,
    details:
      "Sesame seeds are a popular ingredient known for their nutty flavor and high oil content. They are used in cooking, baking, and oil extraction. Sesame seeds are rich in healthy fats, protein, and essential nutrients, making them a valuable addition to a variety of dishes and recipes.",
  },
];

export default productsdata;
