import React, { useState } from "react";
import Slider from "react-slick";
import { slideSlickmultiImage } from "../../page-demo/script";
import "../../assets/css/productDetails.css";

const ProductDetails = ({ product, openQuoteModal }) => {
  const [mainImage, setMainImage] = useState(product?.imageUrl[0]);
  const [activeImageIndex, setActiveImageIndex] = useState(0);

  if (!product) {
    return <div>Product not found</div>;
  }

  const handleImageClick = (image, index) => {
    setMainImage(image);
    setActiveImageIndex(index);
  };

  return (
    <div className="product-details-wrapper">
      <div className="product-details">
        <div className="product-details-image-container">
          <img
            src={mainImage}
            alt={product.name}
            className="product-details-image"
          />

          {/* <Slider {...slideSlickmultiImage}>
            {product.imageUrl.map((image, index) => (
              <img
                key={index}
                src={image}
                alt={`${product.name} ${index + 1}`}
                className={`product-details-slider-image ${
                  index === activeImageIndex
                    ? "product-details-slider-image-active"
                    : ""
                }`}
                onClick={() => handleImageClick(image, index)}
                style={{ cursor: "pointer" }}
              />
            ))}
          </Slider> */}
        </div>
        <div className="product-details-info contact-form--1">
          <h2>{product.name}</h2>
          <p>{product.details}</p>
          <button
            onClick={() => openQuoteModal(product.name)}
            className="getaQuaoteModalButton"
          >
            Submit a quote
          </button>
        </div>
      </div>
    </div>
  );
};

export default ProductDetails;
