import React, { Component } from "react";
import Slider from "react-slick";
import { slideSlick } from "../../page-demo/script";


const defaultSlideList = [
  {
    textPosition: "text-center",
    bgImage: "bg_image--15",
    category: "",
    title: "Marketing",
    description:
      "There are many variations of passages of Lorem Ipsum available but the majority have suffered alteration.",
    buttonText: "Contact Us",
    buttonLink: "/contact-us",
  },
  {
    textPosition: "text-center",
    bgImage: "bg_image--12",
    category: "",
    title: "Development.",
    description:
      "There are many variations of passages of Lorem Ipsum available but the majority have suffered alteration.",
    buttonText: "Contact Us",
    buttonLink: "/blog",
  },
];

class SliderImage extends Component {
  render() {
    const { slideList = defaultSlideList } = this.props;

    return (
      <div className="slider-activation">
        <Slider className="rn-slick-dot dot-light" {...slideSlick}>
          {slideList.map((value, index) => (
            <div
              className={`sliderimageaboutus slide slide-style-2  d-flex align-items-center justify-content-center bg_image ${value.bgImage}`}
              key={index}
            >
              <div className="container">
                <div className="row">
                  <div className="col-lg-12">
                    {/* <div className={`inner ${value.textPosition}`}>
                      {value.category ? <span>{value.category}</span> : ""}
                      {value.title ? (
                        <h1 className="title theme-gradient">{value.title}</h1>
                      ) : (
                        ""
                      )}
                      {value.description ? (
                        <p className="description">{value.description}</p>
                      ) : (
                        ""
                      )}
                      {value.buttonText ? (
                        <div className="slide-btn">
                          <a
                            className="rn-button-style--2 btn-primary-color"
                            href={`${value.buttonLink}`}
                          >
                            {value.buttonText}
                          </a>
                        </div>
                      ) : (
                        ""
                      )}
                    </div> */}
                  </div>
                </div>
              </div>
            </div>
          ))}
        </Slider>
      </div>
    );
  }
}

export default SliderImage;
