import React, { useState } from "react";
import Modal from "react-modal";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faTimes } from "@fortawesome/free-solid-svg-icons";
import Slider from "react-slick";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import "../../assets/css/ataglance.css";
import c1 from "../../assets/images/ataglance/c1.webp";
import c2 from "../../assets/images/ataglance/c2.jpg";
import n1 from "../../assets/images/ataglance/n1.jpg";
import n2 from "../../assets/images/ataglance/n2.jpg";
import h1 from "../../assets/images/ataglance/h1.jpg";
import a1 from "../../assets/images/ataglance/a1.jpg";
import ed1 from "../../assets/images/ataglance/ed1.jpg";
import e1 from "../../assets/images/ataglance/e1.jpeg";
import s1 from "../../assets/images/ataglance/s1.jpg";
import p1 from "../../assets/images/ataglance/p1.webp";

// Set the root element for the modal
Modal.setAppElement("#root");

// Define categories with images and stories
const categories = [
  {
    title: "Cultural Heritage",
    image: c1,
    additionalImages: [c2],
    story: "Explore the rich cultural heritage through these captivating images that showcase the history and traditions of different cultures."
  },
  {
    title: "Tourism and Natural Beauty",
    image: n1,
    additionalImages: [n2],
    story: "Discover the breathtaking landscapes and natural wonders that make tourism an unforgettable experience."
  },
  {
    title: "Cuisine",
    image: h1,
    additionalImages: [a1],
    story: "Savor the flavors of diverse cuisines from around the world, beautifully captured in these images."
  },
  {
    title: "Economic",
    image: ed1,
    additionalImages: [e1],
    story: "Gain insights into the economic development and industrial progress through these thought-provoking images."
  },
  {
    title: "Education",
    image: s1,
    additionalImages: [p1],
    story: "Learn about educational advancements and institutions through these engaging images."
  },
  {
    title: "Sports",
    image: p1,  // Example image, replace with actual sports image
    additionalImages: [c1, n1],
    story: "Immerse yourself in the world of sports with these dynamic and action-packed images."
  },
  {
    title: "Social Progress",
    image: n2,  // Example image, replace with actual social progress image
    additionalImages: [h1],
    story: "Explore the strides in social progress and community development through these insightful images."
  },
  {
    title: "Architecture and Infrastructure",
    image: a1,  // Example image, replace with actual architecture image
    additionalImages: [ed1],
    story: "Admire the architectural marvels and infrastructure projects that shape our world."
  },
  {
    title: "Pop Culture",
    image: e1,  // Example image, replace with actual pop culture image
    additionalImages: [s1],
    story: "Dive into the vibrant world of pop culture with these fun and engaging images."
  },
  {
    title: "Quality of Life",
    image: c2,  // Example image, replace with actual quality of life image
    additionalImages: [p1],
    story: "Understand the factors contributing to a high quality of life through these compelling images."
  }
];

const AtaGlance = () => {
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [currentCategory, setCurrentCategory] = useState(null);

  // Open the modal with the selected category
  const openModal = (category) => {
    setCurrentCategory(category);
    setIsModalOpen(true);
  };

  // Close the modal
  const closeModal = () => {
    setIsModalOpen(false);
    setCurrentCategory(null);
  };

  // Settings for the Slick slider
  const sliderSettings = {
    infinite: true,
    speed: 500,
    slidesToShow: 1,
    slidesToScroll: 1,
    arrows: true,
    autoplay: true, // Enable autoplay
    autoplaySpeed: 3000, 
  };
  

  return (
    <div className="ataglance-image-grid container">
      <div className="row">
        {categories.map((category, index) => (
          <div key={index} className="col-lg-4 col-md-12 mb-4 mb-lg-0">
            <div className="category-image-container">
              <img
                src={category.image}
                className="category-image"
                alt={category.title}
                onClick={() => openModal(category)}
              />
              <div className="category-title">{category.title}</div>
            </div>
          </div>
        ))}
      </div>

      {/* Modal displaying the current category */}
      <Modal
        isOpen={isModalOpen}
        onRequestClose={closeModal}
        contentLabel="Category Modal"
        className="category-modal"
        overlayClassName="category-overlay"
      >
        <button className="close-modal-btn" onClick={closeModal}>
          <FontAwesomeIcon icon={faTimes} />
        </button>
        {currentCategory && (
          <div>
            <h2>{currentCategory.title}</h2>
            <p>{currentCategory.story}</p>
            <Slider {...sliderSettings}>
              {[currentCategory.image, ...(currentCategory.additionalImages || [])].map((image, index) => (
                <div key={index} style={{ display: "flex", alignItems: "center", justifyContent: "center" }}>
                  <img src={image} className="modal-image" alt={`Slide ${index + 1}`} />
                </div>
              ))}
            </Slider>
          </div>
        )}
      </Modal>
    </div>
  );
};

export default AtaGlance;
