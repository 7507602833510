import React, { useState, useEffect } from "react";
import PageHelmet from "../component/common/Helmet";
import { FiClock, FiUser, FiMessageCircle, FiHeart } from "react-icons/fi";
import { Link, useParams } from "react-router-dom";
import ScrollToTop from "react-scroll-up";
import { FiChevronUp } from "react-icons/fi";
import Header from "../component/header/Header";
import ArticleList from "../data/ArticleData";

const ArticleDetails = () => {
  const { id } = useParams();
  const [isOpen, setIsOpen] = useState(false);
  const [article, setArticle] = useState(null);

  useEffect(() => {
    const foundArticle = ArticleList.find((item) => item.id === parseInt(id));
    setArticle(foundArticle);
  }, [id]);

  const openModal = () => {
    setIsOpen(true);
  };

  if (!article) {
    return <div>Loading...</div>;
  }

  return (
    <>
      <PageHelmet pageTitle="Article Details" />
      <Header
        headerPosition="header--fixed sticky logoresize"
        logo="all-dark"
        color="color-black"
      />
      <div
        className="article-details__hero-section pt--120 pb--190"
        style={{
          backgroundImage: `url(${article.images})`,
          backgroundSize: "cover",
          backgroundPosition: "center",
          position: "relative",
        }}
      >
        <div className="container">
          <div className="row">
            <div className="col-lg-12">
              <div className="article-details__title-wrapper text-center pt--100">
                <h2 className="">{article.title}</h2>
                <ul className="article-details__meta d-flex justify-content-center align-items-center">
                  <li>
                    <FiClock />
                    July 5, 2022
                  </li>
                  <li>
                    <FiUser />
                    Kevin Martin
                  </li>
                  <li>
                    <FiMessageCircle />8 Comments
                  </li>
                </ul>
              </div>
            </div>
          </div>
        </div>
      </div>

      {/* Start Article Details Section */}
      <div className="article-details__content-section  bg_color--1">
        <div className="container">
          <div className="row">
            {/* Article Content Area */}
            <div className="col-lg-8">
              <div className="article-details__content">
                <p>{article.content}</p>
                <div className="article-details__thumbnail">
                  <img src={article.images} alt="Blog Images" />
                </div>
                <p className="mt--40"> {article.details}</p>
                <blockquote className="article-details__quote">
                  {article.details}
                </blockquote>
              </div>

              {/* Start Article Comment Form */}
              <div className="article-details__comment-form pb--120 bg_color--1">
                <div className="container">
                  <div className="row">
                    <div className="col-lg-12">
                      <div className="inner">
                        <h3 className="article-details__comment-title mb--40 fontWeight500">
                          Leave a Comment
                        </h3>
                        <form>
                          <div className="row">
                            <div className="col-lg-6 col-md-12">
                              <div className="form-group">
                                <input type="text" placeholder="Full Name" />
                              </div>
                            </div>
                            <div className="col-lg-6 col-md-12">
                              <div className="form-group">
                                <input
                                  type="email"
                                  placeholder="Email Address"
                                />
                              </div>
                            </div>
                          </div>
                          <div className="row">
                            <div className="col-lg-12 col-md-12">
                              <div className="form-group">
                                <textarea placeholder="Write Comment"></textarea>
                              </div>
                            </div>
                            <div className="col-lg-12">
                              <button className="btn btn-primary">
                                Post Comment
                              </button>
                            </div>
                          </div>
                        </form>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              {/* End Article Comment Form */}
            </div>

            {/* Sidebar */}
            <div className="col-lg-4">
              <aside className="article-details__sidebar">
                {/* Recent Posts */}
                <div className="article-details__recent-posts-widget">
                  <h4>Latest Posts</h4>
                  <ul>
                    {ArticleList.slice(0, 3).map((item) => (
                      <li
                        key={item.id}
                        style={{
                          display: "flex",
                          alignItems: "center",
                          marginBottom: "15px",
                        }}
                      >
                        {/* Image preview */}
                        <img
                          src={item.images}
                          alt={item.title}
                          style={{
                            width: "50px",
                            height: "50px",
                            objectFit: "cover",
                            marginRight: "10px",
                            borderRadius: "5px",
                          }}
                        />
                        {/* Article title */}
                        <Link
                          to={`/article-details/${item.id}`}
                          style={{ textDecoration: "none", color: "#000" }}
                        >
                          {item.title}
                        </Link>
                      </li>
                    ))}
                  </ul>
                </div>

                {/* Categories */}
                <div className="article-details__categories-widget">
                  <h4>Category</h4>
                  <ul>
                    <li>
                      <Link to="#">{article.category}</Link>
                    </li>
                  </ul>
                </div>

                {/* Tags */}
                <div className="article-details__tags-widget">
                  <h4>Tags</h4>
                  <ul className="tags">
                    <li>
                      <Link to="#">Harvest</Link>
                    </li>
                    <li>
                      <Link to="#">Vegetables</Link>
                    </li>
                    <li>
                      <Link to="#">Organic</Link>
                    </li>
                  </ul>
                </div>
              </aside>
            </div>
          </div>
        </div>
      </div>
      {/* End Article Details Section */}

      {/* Start Back To Top */}
      <div className="backto-top">
        <ScrollToTop showUnder={160}>
          <FiChevronUp />
        </ScrollToTop>
      </div>
      {/* End Back To Top */}
    </>
  );
};

export default ArticleDetails;
