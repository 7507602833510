import React from "react";
import PropTypes from "prop-types";
import { VideoTag } from "react-video-tag";
import bgImage from "../../assets/images/bg/bg-image-32.jpg";
import "../../assets/css/heroSlider.css";
import Slider from "react-slick";
import { slideSlick } from "../../page-demo/script";


const HeroSlider = ({ video, slideList = [] }) => {
  return (
    <div className="slider-wrapper heroSlider videowrapper">
      {/* Video Background (renders only once) */}
      <div className="video-background">
        <VideoTag
          autoPlay
          muted
          playsInline
          loop
          src={video}
          poster={bgImage}
        />
      </div>

      {slideList.length > 0 && (
        <Slider className="rn-slick-dot dot-light" {...slideSlick}>
          {slideList.map((value, index) => (
            <div
              className="slide slide-style-2 slider-paralax d-flex align-items-center justify-content-center"
              key={index}
            >
              <div className="container">
                <div className="row">
                  <div className="col-lg-12">
                    <div className={`inner ${value.textPosition}`}>
                      {value.category ? <span>{value.category}</span> : ""}
                      {value.title ? (
                        <h1 className="title">{value.title}</h1>
                      ) : (
                        ""
                      )}
                      {value.description ? (
                        <p className="description">{value.description}</p>
                      ) : (
                        ""
                      )}
                    </div>
                  </div>
                </div>
              </div>
            </div>
          ))}
        </Slider>
      )}
    </div>
  );
};

HeroSlider.propTypes = {
  video: PropTypes.string.isRequired,
  slideList: PropTypes.array,
};

export default HeroSlider;
