import React from "react";
import aboutMain from "../assets/images/aboutmain.jpg";

const About = () => {
  return (
    <div class="about-container">
      <div class="about-content">
        <div class="about-image">
          <img src={aboutMain} alt="About Image" />
        </div>
        <div class="about-text">
          <h2>About</h2>
          <p>
            Bangladesh's agricultural product exports have slightly increased
            year-on-year during this fiscal year, marking a turnaround from a
            gradually drop over the past three years thanks to, according to
            industry insiders, restoration of supply chains and a slight
            improvement in the US dollar crisis.
          </p>
          <div class="mission-vision">
            <div class="mission">
              <h3>Mission</h3>
              <p>
                To showcase the finest of Bangladesh's agricultural bounty on
                the global stage, delivering premium-quality products while
                empowering local farmers through sustainable and ethical
                practices.
              </p>
            </div>
            <div class="vision">
              <h3>Vision</h3>
              <p>
                To transform Bangladesh into a global leader in agricultural
                exports, recognized for unparalleled quality, innovation, and
                unwavering commitment to sustainable farming practices.
              </p>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default About;
