import React, { Component, Fragment } from "react";
import ScrollToTop from "react-scroll-up";
import CallAction from "../elements/callaction/CallAction";
import { FiChevronUp } from "react-icons/fi";
import HeroSlider from "../component/slider/HeroSlider";
import WhatWeDo from "../component/HomeLayout/homeOne/WhatWeDo";
import { LeafTitle } from "../component/common/LeafTitle";
import ProductCard from "../component/product/ProductCard";
import { Link } from "react-router-dom/cjs/react-router-dom.min";
import { motion } from "framer-motion";
import { withTranslation } from 'react-i18next';

import p1 from "../assets/images/products/p1.png";
import p2 from "../assets/images/products/p2.png";
import p3 from "../assets/images/products/p3.png";
import p4 from "../assets/images/products/p4.png";
import herovideo from "../assets/images/hero_vid.mp4";
import CounterTwo from "../elements/counters/CounterTwo";
import Header from "../component/header/Header";

const items = [
  { imageUrl: p1, name: "Corn Cob Meal (Commodity item)" },
  { imageUrl: p2, name: "Jackfruit (Fruit)" },
  { imageUrl: p3, name: "Pineapple (Fruit)" },
  { imageUrl: p4, name: "Banana (Fruit)" },
];

const SlideList = [
  {
    textPosition: "text-center",
    category: "",
    title: "",
    description: "",
  },
  {
    textPosition: "text-center",
    category: "",
    title: "",
    description: "",
  },
];

class FarmvalyHome extends Component {
  render() {
    const { t } = this.props;

    const leftVariants = {
      offscreen: {
        x: -300,
        opacity: 0,
      },
      onscreen: {
        x: 0,
        opacity: 1,
        transition: {
          type: "spring",
          bounce: 0.3,
          duration: 0.8,
        },
      },
    };

    const rightVariants = {
      offscreen: {
        x: 300,
        opacity: 0,
      },
      onscreen: {
        x: 0,
        opacity: 1,
        transition: {
          type: "spring",
          bounce: 0.3,
          duration: 0.8,
        },
      },
    };

    return (
      <Fragment>
        <Header
          headerPosition="header--fixed sticky logoresize"
          logo="all-dark"
          color="color-black"
        />
        {/* <Helmet pageTitle={t('farmvaly.pageTitle')} /> */}

        {/* Start Slider Area */}
        <HeroSlider
          video={herovideo}
          slideList={[
            {
              textPosition: "text-center",
              category: "",
              title: t('slider.title1'),
              description: t('slider.description1'),
            },
            {
              textPosition: "text-center",
              category: "",
              title: t('slider.title2'),
              description: t('slider.description2'),
            }
          ]}
        />

        <CounterTwo />

        {/* Start What we do */}
        <WhatWeDo />

        {/* Start Product Showcase Area */}
        <div className="productShowcaseSection text-center">
          <LeafTitle title={t('productShowcase.title')} />

          <div className="container">
            <motion.div
              className="productShowcaseRowWraPPER row justify-between"
              initial={{ y: 90, opacity: 0 }}
              whileInView={{ y: 0, opacity: 1 }}
              transition={{ delay: 0, duration: 0.5 }}
            >
              {items.map((value, index) => (
                <motion.div
                  className="product-card-wrapper HOMEproduct-card-wrapper col-lg-3 col-md-4 col-sm-6"
                  key={index}
                  variants={index % 2 === 0 ? leftVariants : rightVariants}
                >
                  <ProductCard product={value} />
                </motion.div>
              ))}
            </motion.div>

            <div className="moreProductBtnWrapper">
              <Link to="/products" className="moreProductBtn text-center">
                {t('productShowcase.moreProducts')}
              </Link>
            </div>
          </div>
        </div>

        {/* End Product Showcase Area */}

        {/* Start call To Action */}
        <CallAction />

        {/* Start Back To Top */}
        <div className="backto-top">
          <ScrollToTop showUnder={160}>
            <FiChevronUp />
          </ScrollToTop>
        </div>
        {/* End Back To Top */}
      </Fragment>
    );
  }
}

export default withTranslation()(FarmvalyHome);
