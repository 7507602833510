import blog1 from "../assets/images/ataglance/n1.jpg";
import blog2 from "../assets/images/ataglance/n2.jpg";
import blog3 from "../assets/images/ataglance/c1.webp";
import blog4 from "../assets/images/ataglance/ed1.jpg";


const ArticleList = [
  {
    id: 1,
    images: blog1, 
    title: "The Rise of Organic Farming",
    category: "Agriculture",
    details:
      "Explore how organic farming is transforming the agriculture industry, promoting sustainability, and enhancing food quality.",
  },
  {
    id: 2,
    images: blog2, // Replace 'blog2' with the actual image path or URL
    title: "Innovative Techniques in Modern Farming",
    category: "Agriculture",
    details:
      "Discover cutting-edge techniques and technologies that are revolutionizing farming practices and boosting productivity.",
  },
  {
    id: 3,
    images: blog3, // Replace 'blog3' with the actual image path or URL
    title: "Benefits of Farm-Fresh Organic Food",
    category: "Organic Food",
    details:
      "Learn about the numerous health benefits and environmental advantages of consuming farm-fresh organic food.",
  },
  {
    id: 4,
    images: blog4, // Replace 'blog4' with the actual image path or URL
    title: "Sustainable Agriculture: Practices and Benefits",
    category: "Agriculture",
    details:
      "An in-depth look at sustainable agricultural practices, their benefits for the environment, and their role in food security.",
  },
  {
    id: 5,
    images: blog1, // Reused image path for consistency
    title: "Top Organic Products for Your Farm",
    category: "Organic Food",
    details:
      "A guide to the best organic products available for farmers looking to enhance their organic farming operations.",
  },
  {
    id: 6,
    images: blog2, // Reused image path for consistency
    title: "Understanding Agri Products: From Farm to Market",
    category: "Agri Products",
    details:
      "Gain insights into the journey of agricultural products from the farm to the market, including processing and distribution.",
  },
];

export default ArticleList;
