import React from "react";
import HeroSlider from "../component/slider/HeroSlider";
import WhatWeDo from "../component/HomeLayout/homeOne/WhatWeDo";
import CallAction from "../elements/callaction/CallAction";
import herovideo from "../assets/images/hero_vid.mp4";
import Header from "../component/header/Header";
import PageHelmet from "../component/common/Helmet";
const OurBusiness = () => {
  const SlideList = [
    {
      textPosition: "text-center",
      category: "",
      title: "Pure Excellence From Bangladesh",
      description:
        "Farmvaly Cultivating Bangladesh's Agricultural Bounty for the World.",
    },
    {
      textPosition: "text-center",
      category: "",
      title: "Global Reach Local Roots",
      description:
        "Farmvaly Cultivating Bangladesh's Agricultural Bounty for the World.",
    },
  ];
  return (
    <>
      <Header
        headerPosition="header--fixed sticky logoresize"
        logo="all-dark"
        color="color-black"
      />
      <PageHelmet pageTitle="Our Business" />
      <HeroSlider video={herovideo} slideList={SlideList} />
      <WhatWeDo />
      <CallAction />
    </>
  );
};

export default OurBusiness;
