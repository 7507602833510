import React from "react";
import Team from "./../blocks/team/TeamTwo";
import SliderImage from "../component/slider/SliderImage";
import About from "../elements/About";
import BrandPersonality from "../elements/BrandPersonality";

import { LeafTitle } from "../component/common/LeafTitle";
import Header from "../component/header/Header";
import PageHelmet from "../component/common/Helmet";
import CounterTwo from "../elements/counters/CounterTwo";
const AboutUs = () => {
  return (
    <>
      <Header
        headerPosition="header--fixed sticky logoresize"
        logo="all-dark"
        color="color-black"
        textColor="white"
      />
      <PageHelmet pageTitle="About Us" />
      <SliderImage />


      <CounterTwo />
  

      <About />

      {/* End About Area  */}

   

      <BrandPersonality />

      {/* Start Team Area  */}
      <div className="rn-team-area ptb--120 bg_color--1">
        <div className="container">
          <div className="row">
            <div className="col-lg-12">
              <div className="service-style--3 text-center mb--10 mb_sm--0">
                <LeafTitle title={"Management Team"} />
              </div>
            </div>
          </div>

          <Team
            column="col-lg-3 col-md-6 col-sm-6 col-12"
            teamStyle=""
            item="4"
          />
        </div>
      </div>
      {/* End Team Area  */}
    </>
  );
};

export default AboutUs;
