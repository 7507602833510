import React from "react";
import Header from "./../component/header/Header";
import { Link } from "react-router-dom";
import ArticleList from "../data/ArticleData";

const Article = () => {
  return (
    <>
      <Header
        headerPosition="header--fixed sticky logoresize"
        logo="all-dark"
        color="color-black"
      />

      <div className="rn-blog-area ptb--120 bg_color--1">
        <div className="container">
          <div className="row">
            {ArticleList.map((value) => (
              <div className="col-lg-4 col-md-6 col-sm-6 col-12" key={value.id}>
                <div className="blog blog-style--1">
                  <div className="thumbnail">
                    <Link to={`/article-details/${value.id}`}>
                      <img
                        className="w-100"
                        src={value.images}
                        alt="Blog Images"
                      />
                    </Link>
                  </div>
                  <div className="content">
                    <p className="blogtype">{value.category}</p>
                    <h4 className="title">
                      <Link to={`/article-details/${value.id}`}>
                        {value.title}
                      </Link>
                    </h4>
                    <div className="blog-btn">
                      <Link
                        className="rn-btn"
                        to={`/article-details/${value.id}`}
                      >
                        Read More
                      </Link>
                    </div>
                  </div>
                </div>
              </div>
            ))}
          </div>
        </div>
      </div>
    </>
  );
};

export default Article;
