import React, { useState } from "react";
import logo from "../assets/images/logo/logo.png";

const Login = ({ onLogin }) => {
  const [id, setId] = useState("");
  const [password, setPassword] = useState("");
  const [error, setError] = useState("");

  const handleSubmit = (e) => {
    e.preventDefault();
    // Dummy credentials
    const dummyId = process.env.REACT_APP_DUMMYID;
    const dummyPassword = process.env.REACT_APP_DUMMYPASS;
    console.log(dummyId);
    

    if (id === dummyId && password === dummyPassword) {
      onLogin(true);
    } else {
      setError("Invalid ID or Password");
    }
  };

  return (
    <div
      style={{
        height: "100vh",
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
        textAlign: "center",
        fontFamily: "Arial, sans-serif",
        backgroundColor: "#f4f4f4",
      }}
    >
      <div
        style={{
          maxWidth: "500px",
          padding: "20px",
          backgroundColor: "#fff",
          borderRadius: "10px",
          boxShadow: "0 0 10px rgba(0, 0, 0, 0.1)",
          width: "90%",
        }}
      >
        <img
          src={logo}
          alt="Company Logo"
          style={{
            width: "200px",
            height: "auto",
            marginBottom: "10px",
          }}
        />
        <div
          style={{
            fontSize: "1.2em",
            marginBottom: "10px",
            color: "#333",
            fontWeight:"bold"
          }}
        >
          Farmvaly Cultivating Bangladesh's Agricultural Bounty for the World.
        </div>

        <div class="caution" style={{ color: "#FF0000", fontWeight: "bold" , letterSpacing:"2px"}}>
          <i class="fas fa-exclamation-triangle"></i>
          This site is under development.
        </div>
        <form onSubmit={handleSubmit}>
          <div style={{ marginBottom: "10px" }}>
            <label
              style={{
                display: "block",
                marginBottom: "5px",
                textAlign: "left",
                color: "#333",
                fontWeight: "bold",
              }}
            >
              ID:
            </label>
            <input
              type="text"
              value={id}
              onChange={(e) => setId(e.target.value)}
              style={{
                width: "100%",
                padding: "8px",
                borderRadius: "5px",
                border: "1px solid #ccc",
              }}
            />
          </div>
          <div style={{ marginBottom: "10px" }}>
            <label
              style={{
                display: "block",
                marginBottom: "5px",
                textAlign: "left",
                color: "#333",
                fontWeight: "bold",
              }}
            >
              Password:
            </label>
            <input
              type="password"
              value={password}
              onChange={(e) => setPassword(e.target.value)}
              style={{
                width: "100%",
                padding: "8px",
                borderRadius: "5px",
                border: "1px solid #ccc",
              }}
            />
          </div>
          {error && (
            <p
              style={{
                color: "red",
                marginBottom: "10px",
                textAlign: "left",
              }}
            >
              {error}
            </p>
          )}
          <button
            type="submit"
            style={{
              width: "100%",
              padding: "10px",
              backgroundColor: "#28a745",
              color: "#fff",
              borderRadius: "5px",
              border: "none",
              cursor: "pointer",
              fontWeight: "bold",
            }}
          >
            Login
          </button>
        </form>
      </div>
    </div>
  );
};

export default Login;
