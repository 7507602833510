import React from "react";
import { motion } from "framer-motion";
import '../../assets/css/leaftitle.css'

export const LeafTitle = ({ title }) => {
  return (
    <div className="LeafTitle text-center" >
      {/* <motion.img
        initial={{ y: 40, opacity: 0 }}
        whileInView={{ y: 0, opacity: 1 }}
        transition={{ delay: 0.5, duration: 1 }}
        id="leaf"
        src={leaf}
        alt=""
      /> */}
      {/* <motion.h2
        initial={{ y: 90, opacity: 0 }}
        whileInView={{ y: 0, opacity: 1 }}
        transition={{ delay: 0, duration: 0.5 }}
        className="title mb--25"
        
      >
        Farmvaly
      </motion.h2> */}
      <motion.h2
        initial={{ y: 90, opacity: 0 }}
        whileInView={{ y: 0, opacity: 1 }}
        transition={{ delay: 0, duration: 0.5 }}
        className="title mb--25"
      >
        {title}
      </motion.h2>
    </div>
  );
};
